<template>
  <div>
    <b-modal
      size="xl"
      scrollable
      v-model="modalShow"
      :title="this.$i18n.t('admin.bussiness.formPrograms')"
      no-close-on-backdrop
      hide-header-close
    >
      <b-row>
        <b-col cols="4">
          <label class="mt-3 font-weight-bold d-block">{{ $t('admin.role.country') }} *</label>
          <b-form-select
            v-model="formPrograms.pais"
            :options="optionsCountry"
            size="sm"
            class="mt-0 select"
            :disabled="isEdit || isClone"
          >
            <template #first>
              <b-form-select-option :value="0" disabled
                >-- {{ $t('admin.role.selectCountry') }} --</b-form-select-option
              >
            </template>
          </b-form-select>

          <label class="mt-3 font-weight-bold d-block">{{ $t('admin.bussiness.name') }} *</label>
          <b-form-input
            id="name-input"
            v-model="formPrograms.nombre"
            type="text"
            size="sm"
            lazy
            :placeholder="this.$i18n.t('admin.bussiness.nameConfiguration')"
          >
          </b-form-input>
        </b-col>
      </b-row>

      <hr />

      <!-- CONDICIONES PARA SELECCION DE PROGRAMAS -->
      <section>
        <h4 class="mt-4">{{ $t('admin.bussiness.configHubspot') }}</h4>
        <div>
          <b-row
            v-for="(condition, idx) in formPrograms.reglas_productos"
            :key="`prog-${idx}`"
            class="config-bussiness mt-3"
          >
            <b-col cols="6" class="pr-0">
              <div>
                <b-dropdown
                  size="sm"
                  :text="(condition.info_interno && condition.info_interno.label) || textDropdown"
                  variant="outline-secondary"
                >
                  <div class="groupSearch my-3">
                    <b-input-group size="sm" class="inputSearch">
                      <b-form-input
                        id="filter-input-property"
                        v-model="filterProperty"
                        type="search"
                        :placeholder="placeholderText"
                        class="custom-input"
                      >
                      </b-form-input>
                    </b-input-group>
                  </div>
                  <b-dropdown-divider />
                  <div
                    v-for="(item, index) in optionsFilterProperty"
                    :key="`property-prog-${idx}-${index}`"
                    class="item__dropdown"
                  >
                    <b-dropdown-item @click="propertyProgramsSelected(item, condition, idx)">{{
                      item.label
                    }}</b-dropdown-item>
                  </div>
                </b-dropdown>
              </div>
            </b-col>
            <!-- Card de opciones de hubspot -->
            <b-col cols="5" v-if="condition.info_interno && condition.info_interno.type === 'enumeration'">
              <b-card
                header="Items"
                class="text-center"
                v-if="optionsInternoProgram[idx] && optionsInternoProgram[idx].length"
              >
                <b-card-text>
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                      :id="`checkbox-group-prog-${idx}`"
                      v-model="condition.opcion"
                      :aria-describedby="ariaDescribedby"
                      :options="optionsInternoProgram[idx]"
                      name="programs"
                      stacked
                      class="text-left custom-card-prog"
                    >
                    </b-form-checkbox-group>
                  </b-form-group>
                </b-card-text>
              </b-card>
            </b-col>
            <b-col cols="5" v-else-if="condition.info_interno && condition.info_interno.type !== 'enumeration'">
              <b-input-group>
                <b-form-input
                  id="name-input"
                  v-model="condition.value"
                  type="text"
                  size="sm"
                  lazy
                  :placeholder="placeholderProperty"
                >
                </b-form-input>
              </b-input-group>
            </b-col>
            <b-col cols="1">
              <b-icon
                icon="x-circle"
                scale="1.3"
                class=" icon_link"
                variant="danger"
                @click="deletePropertyProgram(idx)"
                v-if="idx !== 0"
              ></b-icon>
            </b-col>
          </b-row>
        </div>
        <b-button size="sm" class="mr-2 mt-3 bg-primary custom-buttom" @click="addPropertyProgram">
          {{ $t('admin.bussiness.addProperty') }} +
        </b-button>
        <!-- Programas a mostrar -->
        <hr />
        <h4 class="mt-4">{{ $t('admin.bussiness.conditionPrograms') }}</h4>
        <b-card :header="this.$i18n.t('admin.bussiness.product')" class="text-center w-50 mt-3">
          <b-card-text>
            <div>
              <div class="groupSearch mb-3">
                <b-input-group size="sm" class="inputSearch">
                  <b-form-input
                    id="search-labs"
                    v-model="filterOptions"
                    type="search"
                    :placeholder="this.$i18n.t('admin.bussiness.searchProduct')"
                    class="custom-input"
                  >
                  </b-form-input>
                </b-input-group>
              </div>
              <hr />
            </div>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                id="checkbox-group-products"
                v-model="formPrograms.productos"
                :aria-describedby="ariaDescribedby"
                :options="optionsCategoryItem"
                name="optionsPrograms"
                stacked
                class="text-left custom-card-category"
              >
              </b-form-checkbox-group>
              <b-alert show v-if="optionsCategoryItem.length === 0">{{
                $t('admin.bussiness.msgSelectCountry')
              }}</b-alert>
              <span v-if="isLoadingPrograms"
                ><b-spinner small class="align-middle"></b-spinner>
                {{ $t('admin.medicines.loading') }}
              </span>
            </b-form-group>
          </b-card-text>
        </b-card>
        <hr />
        <!-- Condiciones Pdf -->
        <h4 class="mt-4">{{ $t('admin.bussiness.pdfCondicion') }}</h4>
        <b-row>
          <b-col cols="5">
            <label class="mt-3"
              ><b>{{ $t('admin.bussiness.showPDF') }}</b></label
            >
            <b-form-select
              v-model="formPrograms.reglas_pdf.principal"
              :options="optionsPrincipalPDF"
              size="sm"
              class="select"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- {{ $t('admin.bussiness.colorShow') }} --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="5"   v-if="formPrograms.pais === 1"> 
            <!-- v-if="formPrograms.pais === 1" -->
            <label class="mt-3"
              ><b>{{ $t('admin.bussiness.showPDF') }} ({{ $t('admin.bussiness.optional') }})</b></label
            >
            <b-form-select
              v-model="formPrograms.reglas_pdf.opcional"
              :options="optionsSecondaryPDFMX"
              size="sm"
              class="select"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- {{ $t('admin.bussiness.optionalColor') }} --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="5"   v-if="formPrograms.pais === 2"> 
            <label class="mt-3"
              ><b>{{ $t('admin.bussiness.showPDF') }} ({{ $t('admin.bussiness.optional') }})</b></label
            >
            <b-form-select
              v-model="formPrograms.reglas_pdf.opcional"
              :options="optionsSecondaryPDF"
              size="sm"
              class="select"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- {{ $t('admin.bussiness.optionalColor') }} --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-col>
        </b-row>
      </section>

      <hr />

      <template #modal-footer>
        <b-row>
          <b-col class="justify-content-end buttons-dropdown">
            <b-button class="btn btn-blue-dark-outline mr-3" @click="handleCloseModal">
              {{ $t('admin.users.cancel') }}
            </b-button>
            <b-button class="btn btn-blue-dark-outline mr-3" @click="clearProgramForm" v-if="!isEdit">
              {{ $t('admin.bussiness.clear') }}
            </b-button>
            <b-button class="btn btn-blue-dark ml-0" @click="handleConfig" :disabled="isLoadingDone || !validateForm">
              <span v-if="!isLoadingDone">
                <span v-if="isEdit">{{ $t('admin.role.edit') }}</span>
                <span v-else>{{ $t('admin.role.create') }}</span>
                <b-icon icon="arrow-right" class="icon ml-2" aria-hidden="true"></b-icon>
              </span>
              <span v-else
                ><b-spinner small class="align-middle"></b-spinner>
                {{ $t('admin.medicines.loading') }}
              </span>
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { API_ROOT } from '../../../Constants/Api';
import { AlertForm, ICON } from '../../../helpers/Alert';
import { ApiRequest } from '../../../helpers/ApiRequest';

export default {
  name: 'FormBussinessPrograms',
  components: {},
  props: ['modalShow', 'getListConfiguration', 'isEdit', 'configurationForm', 'isClone'],
  data: () => ({
    isLoadingDone: false,
    isLoadingPrograms: false,

    formPrograms: {
      pais: 0,
      nombre: null,
      estado: true,
      reglas_productos: [
        {
          nombre_interno: null,
          opcion: [],
          info_interno: null,
          value: null,
        },
      ],
      reglas_pdf: {
        principal: null,
        opcional: null,
      },
      productos: [],
    },
    // options
    optionsSucursal: [],
    optionsCountry: [
      { text: 'USA', value: 2 },
      { text: 'Mexico', value: 1 },
    ],
    loadingBranch: false,
    optionsInternoProgram: [],
    allOptionsCategoryItem: [],
    optionsCategoryItem: [],
    optionsFilterProperty: [],
    optionsProperty: [],
    filterProperty: '',
    filterOptions: '',
    //pdf

    optionsPrincipalPDF: [
      { text: 'Azul', value: 'Azul' },
      { text: 'Verde', value: 'Verde' },
    ],

    optionsSecondaryPDF: [
      { text: 'Amarillo', value: 'Amarillo' },
      { text: 'Intermedio', value: 'Intermedio' },
      { text: 'Blanco_Puntaje Minimo', value: 'Blanco_Puntaje Minimo' },
    ],
    //Crear Configuracion Admin&Program PDF
     optionsSecondaryPDFMX: [
      { text: 'Amarillo', value: 'Amarillo' },
      { text: 'Intermedio-Alto', value: 'Intermedio-H' },
      { text: 'Intermedio-Bajo', value: 'Intermedio-L' },
      { text: 'Blanco_Puntaje Minimo', value: 'Blanco_Puntaje Minimo' },
    ],
  }),
  async mounted() {
    try {
      let data = await ApiRequest.get(`${API_ROOT}pipelines/propsContact`);
      this.optionsProperty = data.data;
      this.optionsFilterProperty = data.data;
    } catch (err) {
      AlertForm(`Error Code: ${err.status}`, this.$i18n.t('admin.bussiness.errorListPipelines'), ICON.ERROR);
    }
  },
  computed: {
    pais() {
      return this.formPrograms.pais;
    },
    validateForm() {
      let validateFields = this.formPrograms.reglas_productos.every(
        (el) => el.nombre_interno !== null && (el.info_interno.options.length === 0 || el.opcion.length !== 0)
      );
      let validatePdf =
        this.formPrograms.reglas_pdf.principal &&
        (this.formPrograms.reglas_pdf.opcional || this.formPrograms.pais === 2);
      return (
        this.formPrograms.pais !== 0 &&
        this.formPrograms.nombre &&
        this.formPrograms.productos.length > 0 &&
        this.formPrograms.reglas_productos.length > 0 &&
        validateFields &&
        validatePdf
      );
    },
    placeholderText() {
      return this.$i18n.t('welcome.search');
    },
    placeholderProperty() {
      return this.$i18n.t('admin.bussiness.PropertyValue');
    },
    textDropdown() {
      return this.$i18n.t('admin.pharmacy.select');
    },
  },
  watch: {
    configurationForm() {
      if (this.configurationForm) {
        this.formPrograms = {
          pais: this.configurationForm.pais === 'Mx' ? 1 : 2,
          nombre: this.isClone ? null : this.configurationForm.nombre,
          reglas_productos: this.configurationForm.configReglasProductos,
          productos: this.configurationForm.productos,
          id: this.configurationForm._id,
          estado: this.configurationForm.estado,
          reglas_pdf: this.configurationForm.reglas_pdf,
        };
        this.optionsInternoProgram = this.formPrograms.reglas_productos.map((el) =>
          el.info_interno.options.map((el) => ({
            text: el.value,
            value: el.value,
          }))
        );
      }
    },
    async pais() {
      if (this.pais) {
        if (!(this.isEdit || this.isClone)) this.formPrograms.productos = [];
        await this.getProductsXCountry(this.pais);
      }
    },

    filterOptions() {
      this.optionsCategoryItem = this.allOptionsCategoryItem.filter((el) =>
        el.text.toLowerCase().includes(this.filterOptions)
      );
    },

    filterProperty() {
      this.optionsFilterProperty = this.optionsProperty.filter((el) =>
        el.label.toLowerCase().includes(this.filterProperty)
      );
    },
  },

  methods: {
    async handleConfig() {
      for (const item of this.formPrograms.reglas_productos) {
        if (item.opcion.length) {
          item.value = null;
        }
      }
      this.isLoadingDone = true;
      if (this.isEdit) {
        try {
          await ApiRequest.put(`${API_ROOT}pipelines/programas-score`, this.formPrograms);
          AlertForm(this.$i18n.t('admin.bussiness.programsUpdate'), '', ICON.SUCCESS, 'center');
          this.clearProgramForm();
          this.getListConfiguration();
          this.$emit('closeModal');
        } catch (err) {
          AlertForm(`Error Code: ${err.status}`, this.$i18n.t('admin.bussiness.errorUpdateProgram'), ICON.ERROR);
        } finally {
          this.isLoadingDone = false;
        }
      } else {
        try {
          await ApiRequest.post(`${API_ROOT}pipelines/programas-score`, this.formPrograms);
          AlertForm(this.$i18n.t('admin.bussiness.programsCreate'), '', ICON.SUCCESS, 'center');
          this.clearProgramForm();
          this.getListConfiguration();
          this.$emit('closeModal');
        } catch (err) {
          if (err.status === 500 && err.data.msg.includes('duplicate')) {
            AlertForm(`Error Code: ${err.status}`, this.$i18n.t('admin.bussiness.duplicateProgram'), ICON.ERROR);
          } else {
            AlertForm(`Error Code: ${err.status}`, this.$i18n.t('admin.bussiness.errorCreateProgram'), ICON.ERROR);
          }
        } finally {
          this.isLoadingDone = false;
        }
      }
    },

    async getProductsXCountry(pais) {
      try {
        this.isLoadingPrograms = true;
        this.optionsCategoryItem = [];
        const data = await ApiRequest.post(`${API_ROOT}cards/productosCategoriaPais`, {
          localidad: pais,
        });
        let allProductsBec = data.categorias_productos.BEC || data.categorias_productos['BEC INTERNACIONAL'];
        this.optionsCategoryItem = allProductsBec.map((el) => {
          return {
            text: el,
            value: el.split('|')[0],
          };
        });
        this.allOptionsCategoryItem = this.optionsCategoryItem;
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t('admin.top.errorProducts'), ICON.ERROR);
      } finally {
        this.isLoadingPrograms = false;
      }
    },

    addPropertyProgram() {
      this.formPrograms.reglas_productos.push({
        nombre_interno: null,
        opcion: [],
        info_interno: null,
        value: null,
      });
    },
    deletePropertyProgram(index) {
      this.formPrograms.reglas_productos.splice(index, 1);
      this.optionsInternoProgram.splice(index, 1);
    },

    propertyProgramsSelected(item, condition, index) {
      condition.opcion = [];
      let dataOption = item.options.map((el) => ({
        text: el.value,
        value: el.value,
      }));
      condition.info_interno = item;
      condition.nombre_interno = item.name;
      if (dataOption.length === 0) condition.opcion = [];
      this.optionsInternoProgram.splice(index, 1, dataOption);
    },
    handleCloseModal() {
      this.clearProgramForm();
      this.$emit('closeModal');
    },
    /* limpiar el form */
    clearProgramForm() {
      this.formPrograms = {
        pais: 0,
        nombre: null,
        estado: true,
        reglas_productos: [
          {
            nombre_interno: null,
            opcion: [],
            info_interno: null,
            value: null,
          },
        ],
        reglas_pdf: {
          principal: null,
          opcional: null,
        },
        productos: [],
      };
      this.optionsCategoryItem = [];
      this.optionsInternoProgram = [];
    },
  },
};
</script>

<style scoped>
@import '../../../assets/css/global.css';

.custom-actions {
  cursor: pointer;
}

.custom-card-category {
  max-height: 250px;
  overflow-y: auto;
}

.custom-buttom:disabled {
  cursor: not-allowed;
}

.config-bussiness /deep/ .btn-group {
  width: 100%;
}
.config-bussiness /deep/ .btn-group > button {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.config-bussiness /deep/ .dropdown-menu.show {
  width: 100% !important;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 0.9rem;
}

.groupSearch {
  padding: 0 1rem;
}

.icon_link {
  cursor: pointer;
  opacity: 0.75;
}
/* delete */
.delete-item:hover {
  color: red;
  text-decoration: underline;
  cursor: pointer;
}

.rol .configuracion {
  margin-top: 4rem;
}

.rol /deep/ .headerClass {
  color: var(--gray-color);
  font-size: 0.8rem;
}

.modal-config label {
  font-weight: bold;
  margin-top: 1rem;
}
</style>
